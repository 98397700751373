/* CSS document -> App uses tailwindcss */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playwrite+FR+Trad:wght@100..400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


body {
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  user-select: none; /* Disables text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
  background: #E1F0F8F7;
}

/* roboto fonts */
.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}















.overflow-hidden{
  overflow: hidden;
}
/* p{font-weight: 500;} */
/* sweet alert custom */
.swal2-popup.swal2-modal.swal2-show {
  width: auto !important;
}
.search-item-filter{
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
.swal2-container{background:#fff !important;}
.swal2-popup.swal2-modal.swal2-icon-error.swal2-show,
.swal2-popup.swal2-modal.swal2-icon-success.swal2-show {
  width: 32em !important;
}
/* .Toastify__toast-container{
  width: auto !important;
} */

.dropdown-container{
  border-radius: 0 !important;
}

.playwrite-fr-trad {
  font-family: "Playwrite FR Trad", sans-serif !important;
  font-weight: 900;
}

.notification-pointer{
  display: block;
  background: #ff3802;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: 0;
}

.Toastify__toast-body {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
  font-weight: 500;
}
.Toastify__toast-icon {
  margin-inline-end: 15px !important;
  width: 30px !important;
}

.react-tel-input .form-control {
  font-size: 16px !important;
  padding: 23px 15px 23px 50px !important;
  width: 100% !important;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid #9ca3af !important;
}

.react-tel-input .form-control.danger {
  border: 1px solid #b91c1c !important;
}

.react-tel-input .form-control.danger~.flag-dropdown {
  border: 1px solid #b91c1c !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid #9ca3af !important;
}

.loader {
  border-top-color: #00d463; /* Tailwind primary color */
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.custom-min-width {
  max-width: 35%;
}
input, textarea{
  outline: none !important;
}

.composer-area .dropdown-container{
  border-radius: 8px !important;
}

/* In your CSS or Tailwind styles */
.custom-audio-player {
  width: 100%;
  border-radius: 8px;
  background-color: #f4f4f4;
  border-radius: 10px;
}

/* Specific pseudo-elements for further customization might vary across browsers */
audio::-webkit-media-controls-panel {
  background-color: #f4f4f4;
  border-radius: 10px;
}

/* audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-volume-slider {
  filter: invert(1);
} */



@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

/* custom responsive */
.sm-responsive{
  display: block;
}

@media screen and (max-width: 500px) {
  /* Your CSS rules go here */
  .sm-responsive{
    display: none;
  }
}

.custom-scrollbar {
  /* Set a fixed height or width to enable overflow */
  height: 100vh;
  /* Width can also be set for horizontal scroll */
  overflow-y: auto; /* Enable vertical scroll */
  overflow-x: hidden; /* Hide horizontal scroll */
}

/* Style the scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #bdbdbd; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; /* Change color on hover */
}



.like-button .heart-icon {
  height: 50px;
  width: 50px;
  background: url("../custom-icons/heart.png");
  background-position: left;
  cursor: pointer;
  position: absolute;
  background-size: cover;
}

.like-button .heart-icon.liked {
  animation: like-anim 0.7s steps(28) forwards;
}

@keyframes like-anim {
  to {
    background-position: right;
  }
}

.like-button .heart-bg {
  background: rgba(255, 192, 200, 0);
  border-radius: 50%;
  height: 16px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms ease;
}

.like-button {
  display: flex;
  align-items: center;
}